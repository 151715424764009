import { render, staticRenderFns } from "./GeneralLedger.vue?vue&type=template&id=0c3a463a"
import script from "./GeneralLedger.vue?vue&type=script&lang=js"
export * from "./GeneralLedger.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports